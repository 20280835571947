import {useEffect} from 'react';
import * as Yup from 'yup';
import {makeStyles} from '@mui/styles';
import {useHistory} from 'react-router-dom';
// components
import LoginView from '../../../../components/LoginView/LoginView';
import LogoComponent from '../../../Login/components/LogoComponent';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: '0.5em',
    width: '95%',
  },
}));

function PasswordReset() {
  let history = useHistory();

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/');
    }
  }, [history]);

  const classes = useStyles();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
  });

  function onSubmit(data, {setSubmitting}) {
    setSubmitting(true);
    alertService.clear();
    accountService
      .resetPassword(data)
      .then(() => {
        alertService.success(
          <>{`An email has been sent. Please follow the link in the email to complete your password reset request.`}</>,
          {autoClose: false}
        );
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response?.data && error?.response?.data?.message) {
          alertService.error(error.response.data.message);
        } else {
          alertService.error(error?.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const fields = [
    {
      label: 'Please enter your email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
  ];

  return (
    <>
      <LoginView
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Reset password"
        fields={fields}
        logo={<LogoComponent />}
      />
      <CustomAlert id="default-alert" className={classes.inputField} />
    </>
  );
}

export {PasswordReset};
